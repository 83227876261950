import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Built with `}<a parentName="p" {...{
        "href": "https://github.com/MunifTanjim/gatsby-theme-dox"
      }}>{`Dox theme`}</a>{` by `}<a parentName="p" {...{
        "href": "https://github.com/MunifTanjim"
      }}>{`MunifTanjim`}</a>{` for `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org"
      }}>{`Gatsby`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      